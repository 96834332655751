<template>
  <div class="search-header relative h-36">
    <a-button class="absolute top-0 right-0"
              type="primary"
              @click="goCreateCustomerGroup">
      <PlusOutlined />新建群发
    </a-button>
  </div>

  <a-table :columns="columns"
           :data-source="group"
           :scroll="{ x: '100%', y: 'calc(100vh - 322px)' }"
           :pagination="pagination"
           row-key="id"
           :loading="tableLoading"
           @change="onPaginationChange">
    <template #sendType="{ record }">
      {{ record.sendType === sendTypeEnum.SEND_NOW ?  '立即发送' : '定时发送'}}
    </template>

    <template #sendTime="{ record }">
      {{ $f.datetime(record.sendTime, 'YYYY-MM-DD HH:mm') }}
    </template>

    <template #sendContent="{ record }">
      <div class="line-clamp-2">{{ record.contents[0]?.content || ' ' }}</div>
    </template>

    <template #staffUnsendNum="{ record }">
      {{ record.staffTotalNum - record.staffSendNum }}
    </template>

    <template #action="{ record }">
      <a-button class="mr-12"
                type="link"
                @click="sendVisible = true; groupId = record.id">发送提醒</a-button>
      <router-link :to="{path:'/customer/customerGroupDetail',query:{ id: record.id}}">详情</router-link>
    </template>
  </a-table>
  <a-modal v-model:visible="sendVisible"
           title="提示"
           width="400px"
           :confirm-loading="confirmLoading"
           @ok="handleSend">
    <p>确认后将会给所有未发送成员发送提醒通知，是否发送？</p>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
import { message } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

import groupSendApi from "@/service/api/groupSend.js";
import liveApi from "@/service/api/live";

export default defineComponent({
  name: "CustomerGroup",
  components: {
    PlusOutlined,
  },

  setup: function () {
    const route = new useRoute();
    const router = new useRouter();

    const { roomId } = route.query;

    const getTableApi = computed(() => {
      if (roomId) {
        return liveApi.getMemberShareLive;
      }

      return groupSendApi.search;
    });

    const searchForm = reactive({
      roomId,
    });

    const sendTypeEnum = {
      SEND_NOW: 0,
      SEND_TIMED: 1,
    };

    const columns = [
      {
        title: "群发类型",
        dataIndex: "sendType",
        width: "10.5%",
        slots: { customRender: "sendType" },
      },
      {
        title: "发送时间",
        dataIndex: "sendTime",
        slots: { customRender: "sendTime" },
      },
      {
        title: "发送内容",
        dataIndex: "sendContent",
        slots: { customRender: "sendContent" },
      },
      {
        title: "已发送成员",
        dataIndex: "staffSendNum",
        key: "staffSendNum",
      },
      {
        title: "已送达客户",
        dataIndex: "receivedCustomerNum",
      },

      {
        title: "未发送成员",
        dataIndex: "staffTotalNum",
        slots: { customRender: "staffUnsendNum" },
      },
      {
        title: "未送达客户",
        dataIndex: "missedCustomerNum",
      },
      {
        title: "操作",
        key: "action",
        width: "15%",
        slots: { customRender: "action" },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, getTableApi.value);

    const confirmLoading = ref(false);
    const sendVisible = ref(false);

    const groupId = ref(0);

    const handleSend = async () => {
      confirmLoading.value = true;

      await groupSendApi.sendNotice(groupId.value).finally(() => {
        confirmLoading.value = false;
        sendVisible.value = false;
      });

      message.success("发送成功");
    };

    const goCreateCustomerGroup = () => {
      if (roomId) {
        router.push({
          path: "/marketingUtil/messageShareCreate",
          query: {
            roomId,
          },
        });
        return;
      }

      router.push({
        path: "/customer/createGroupSend",
      });
    };

    return {
      roomId,
      sendTypeEnum,

      columns,

      group: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      confirmLoading,
      sendVisible,
      groupId,
      handleSend,

      goCreateCustomerGroup,
    };
  },
});
</script>
<style  scoped>
</style>